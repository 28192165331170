import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_HOMECONTENT = 'getHomeContent';
export const GET_PARENTSECTION = 'getParentSection';
export const ADD_SECTIONCONTENT = 'addSectionContent';
export const UPDATE_SECTIONCONTENT = 'updateSectionContent';
export const DETAIL_SECTIONCONTENT = 'detailSectionContent';
export const DELETE_SECTIONCONTENT = 'deleteSectionContent';

// mutations
export const SET_HOMECONTENT = 'setHomeContent';

const status_obj = {
  active: {
    label: 'Active',
    color: 'tag-success',
  },
  not_active: {
    label: 'Inactive',
    color: 'tag-danger',
  },
};

const state = {
  home_content: {},
};

const getters = {
  home_content(state) {
    return state.home_content;
  },
};

const actions = {
  [GET_HOMECONTENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/section/home', param)
        .then(async ({ data }) => {
          await context.commit(SET_HOMECONTENT, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PARENTSECTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/parent-section', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ADD_SECTIONCONTENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/section/home', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_SECTIONCONTENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/section/home/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_SECTIONCONTENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/section/home/${param.id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DETAIL_SECTIONCONTENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/section/home/${param.id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_HOMECONTENT](state, data) {
    data.rows = data.rows.map((v) => {
      v.section_name = v.home_section.section_name;
      ['created_at', 'updated_at'].forEach((kv) => {
        if (v[kv]) v[`${kv}_str`] = moment(v[kv]).format('DD/MM/YYYY');
      });
      v.status_str = status_obj[v.status].label;
      v.status_color = status_obj[v.status].color;
      return v;
    });
    state.home_content = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
