import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_REFUND_REQUEST = 'getRefundRequest';
export const REQUEST_REFUND_MANUAL = 'requestRefundManual';
export const REQUEST_REFUND_ATTACHMENT = 'requestRefundAttachment';
export const DETAIL_REFUND_REQUEST = 'getDetailRefundRequest';
export const APPROVAL_REFUND_REQUEST = 'updateApprovalRefundRequest';
export const DISBURSE_REFUND_REQUEST = 'updateDiburseStatusRefundRequest';

// mutations
export const SET_REFUND_REQUEST = 'setRefundRequest';

const approval_status = {
  waiting_approval: {
    text: 'Waiting Approval',
    color: 'tag-warning',
  },
  approved: {
    text: 'Approved',
    color: 'tag-success',
  },
  rejected: {
    text: 'Rejected',
    color: 'tag-danger',
  },
};

const state = {
  refund_request: {},
};

const getters = {
  refund_request(state) {
    return state.refund_request;
  },
};

const actions = {
  [GET_REFUND_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/refund-request', param)
        .then(async ({ data }) => {
          await context.commit(SET_REFUND_REQUEST, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DETAIL_REFUND_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/refund-request/${param.id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVAL_REFUND_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/refund-request/${param.id}/approval`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISBURSE_REFUND_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/refund-request/${param.id}/refund-status`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REQUEST_REFUND_MANUAL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/refund-request`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REQUEST_REFUND_ATTACHMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/refund-request/attachment`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_REFUND_REQUEST](state, data) {
    let rows = [];
    if (data.count) {
      rows = data.rows.map((v) => {
        ['created_at', 'updated_at'].forEach((kv) => {
          v[`${kv}_str`] = v[kv] ? moment(v[kv]).format('DD MMMM YYYY, HH:mm') : '-';
        });
        v.approval_status_str = approval_status[v.approval_status].text;
        v.approval_status_color = approval_status[v.approval_status].color;
        v.order.amount_str = `Rp. ${v.order.total_transaction.toLocaleString()}`;
        return v;
      });
    }
    data.rows = rows;
    state.refund_request = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
