import ApiService from './api.service';

// actions
export const GET_ACADEMYS = 'getAcademys';
export const CREATE_ACADEMYS = 'createAcademys';
export const UPDATE_ACADEMY = 'updateAcademys';
export const ANALYTIC_ACADEMYS = 'analyticAcademys';

// mutations
export const SET_ACADEMYS = 'setAcademys';
export const SET_ANALYTIC_ACADEMYS = 'setAnalyticAcademys';

const state = {
  academys: {},
  analytic_academy: {},
};

const getters = {
  academys(state) {
    return state.academys;
  },
  analytic_academy(state) {
    return state.analytic_academy;
  },
};

const actions = {
  [GET_ACADEMYS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/academys', param)
        .then(async ({ data }) => {
          await context.commit(SET_ACADEMYS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_ACADEMYS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/academys', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_ACADEMY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/academys/${param.id}`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ANALYTIC_ACADEMYS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/academy', param)
        .then(async ({ data }) => {
          await context.commit(SET_ANALYTIC_ACADEMYS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_ACADEMYS](state, data) {
    let rows = [];
    try {
      rows = data.rows.map((v) => {
        // v.academic_price_money_str = `Rp. ${v.academic_price_money ? v.academic_price_money.toLocaleString() : 0}`;
        // v.source_name = v.academic_name;
        // v.source_id = v.academic_id;
        // v.status_str = v.academic_status === 1 ? 'Active' : 'Inactive';
        // v.status_color_str = v.academic_status === 1 ? 'tag-success' : 'tag-danger';
        return v;
      });
    } catch (error) {
      console.log(error);
    }
    data.rows = rows;
    state.academys = data;
  },
  [SET_ANALYTIC_ACADEMYS](state, data) {
    state.analytic_academy = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
