import ApiService from './api.service';

// actions
export const GET_MY_ROLE = 'getMyRole';
export const GET_MY_ROLE_ALL = 'getMyRoleAll';
export const GET_ALL_ROLES = 'getAllRoles';

// mutations
export const SET_CURRENT_ROLE = 'setCurrentRole';

const state = {
  current_role: {},
};

const getters = {
  current_role(state) {
    return state.current_role;
  },
};

const actions = {
  [GET_MY_ROLE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/me/roles/${param}`)
        .then(async ({ data }) => {
          await context.commit(SET_CURRENT_ROLE, data.data.role);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MY_ROLE_ALL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/me/roles', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_ALL_ROLES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/roles', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_CURRENT_ROLE](state, data) {
    state.current_role = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
