import './assets/styles/index.css';
// import './assets/styles/dashboard.css';
import './assets/styles/tabler.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from './store/modules/api.service';

import i18n from './library/vue-i18n';
import './library/vue-element-ui';
import './library/vue-meta';
import './library/vue-feather';
import './library/vue-loading-overlay';
import './library/bootstrap-vue';
import './library/vue-fontawesome';
// import './library/vue-perfect-scroll';
import './library/vue-apexchart';
import './library/vue-tabler-icons';
import 'popper.js';

Vue.config.productionTip = false;

ApiService.init();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
