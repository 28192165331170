import ApiService from './api.service';

// actions
export const GET_ACTIVITYS = 'getActivitys';

// mutations
export const SET_ACTIVITYS = 'setActivitys';

const state = {
  activitys: {},
};

const getters = {
  activitys(state) {
    return state.activitys;
  },
};

const actions = {
  [GET_ACTIVITYS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/activitys', param)
        .then(async ({ data }) => {
          await context.commit(SET_ACTIVITYS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_ACTIVITYS](state, data) {
    if (data.count == null) {
      data.rows = data.rows.filter((v) => {
        v.source_name = v.activities_name;
        v.source_id = v.activities_id;
        return v;
      });
    }
    state.activitys = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
