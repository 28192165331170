import { upperFirst } from 'lodash';
import ApiService from './api.service';

// actions
export const GET_MYBENEFIT = 'getMyBenefitList';
export const GET_PACKAGE = 'getPackageList';
export const GET_MYBENEFIT_DETAIL = 'getMyBenefitDetail';
export const GET_PACKAGE_DETAIL = 'getPackageDetail';
export const ASSIGN_PACKAGE = 'assignPackageBenefit';
export const UNASSIGN_PACKAGE = 'unassignPackageBenefit';
export const GET_MYBENEFIT_VOUCHER = 'getListMyBenefitVoucher';
export const GET_MYBENEFIT_REQUEST = 'getListRequest';
export const GET_MYBENEFIT_REQUEST_COMPANY = 'getCompanyListRequest';
export const APPROVAL_MYBENEFIT_REQUEST = 'approvalBenefitRequest';
export const ANALYTIC_MYBENEFIT_COMPANY = 'analyticBenefitCompany';
export const UPDATE_MY_BENEFIT = 'updateMyBenefit';
export const UPDATE_PACKAGE_DETAIL = 'updatePackageDetail';

// mutations
export const SET_MYBENEFIT = 'setMyBenefitList';
export const SET_MYBENEFIT_DETAIL = 'setMyBenefitDetail';

const state = {
  mybenefit_lists: {},
  benefit_list: [],
  benefit_detail_list: [],
};

const getters = {
  mybenefit_lists(state) {
    return state.mybenefit_lists;
  },
  benefit_list(state) {
    return state.benefit_list;
  },
};

const actions = {
  [GET_MYBENEFIT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-package', param)
        .then(async ({ data }) => {
          await context.commit(SET_MYBENEFIT, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PACKAGE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-package', param)
        .then(async ({ data }) => {
          await context.commit(SET_MYBENEFIT, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MYBENEFIT_DETAIL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/mybenefit-package/${param.id}`)
        .then(async ({ data }) => {
          await context.commit(SET_MYBENEFIT_DETAIL, data.data);

          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PACKAGE_DETAIL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/mybenefit-package/${param.id}`)
        .then(async ({ data }) => {
          await context.commit(SET_MYBENEFIT_DETAIL, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_MY_BENEFIT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/redeem-voucher/venteny/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_PACKAGE_DETAIL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/mybenefit-package/${param.id}/detail`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ASSIGN_PACKAGE](context, param) {
    return new Promise((resolve, reject) => {
      param.data.mybenefit_package_id = param.id;
      ApiService.put(`api/mybenefit/${param.id}/assign`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UNASSIGN_PACKAGE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/mybenefit/${param.id}/unassign`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MYBENEFIT_VOUCHER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-package/product', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MYBENEFIT_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-request', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVAL_MYBENEFIT_REQUEST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/mybenefit-request/${param.id}/${param.action}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_MYBENEFIT_REQUEST_COMPANY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-request/company-list', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ANALYTIC_MYBENEFIT_COMPANY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/mybenefit-package/analytic/company', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // const getDetailBenefit = async ({state}) => {
  //   if(state.data.length > 0) {
  //     for (const el of state.data) {
  //       let combineData = state.data
  //       let preDetail  = await GET_PACKAGE_DETAIL(el.id)
  //       combineData.details = preDetail

  //       setDetailBenefit(state, combineData)
  //     }
  //   }
  // }
};

const mutations = {
  [SET_MYBENEFIT](state, data) {
    state.mybenefit_lists = data;
  },
  [SET_MYBENEFIT_DETAIL](state, data) {
    try {
      if (!data.detail) {
        return;
      }
      state.benefit_list = data.detail.map((v) => {
        v.benefit_type = upperFirst(v.source_type);
        if (v.source_type === 'mybenefit_insurances') {
          v.benefit_type = 'Insurance';
        }
        v.benefit_name = '-';
        if (v.source_type === 'voucher') {
          v.benefit_name = v.source_name.voucher_name;
        } else if (v.source_type === 'promo') {
          v.benefit_name = v.source_name[0] ? v.source_name[0].promo_name : 'Promo';
        } else if (v.source_type === 'mybenefit_insurances') {
          v.benefit_name = v.source_name.name;
        }
        return v;
      });
    } catch (error) {
      console.log(error);
    }
  },
  // setDetailBenefit(state, data){
  //   state.benefit_detail_list = data
  // }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
