import { upperFirst } from 'lodash';
import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_PROMOS = 'getPromos';
export const CREATE_PROMO = 'createPromo';
export const UPDATE_PROMO = 'updatePromo';
export const DELETE_PROMO = 'deletePromo';
export const APPROVE_REJECT_PROMO = 'approveRejectPromo';
export const ENABLE_DISABLE_PROMO = 'enableDisablePromo';
export const GET_DETAIL_PROMO = 'getDetailPromo';
export const GET_HISTORY_PROMO = 'getHistoryPromo';

// mutations
export const SET_PROMOS = 'setPromos';
export const SET_PROMO_HISTORIES = 'setPromoHistories';

const state = {
  promos: {},
  promo_histories: {},
};

const getters = {
  promos(state) {
    return state.promos;
  },
  promo_histories(state) {
    return state.promo_histories;
  },
};

const actions = {
  [GET_PROMOS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/promo', param)
        .then(async ({ data }) => {
          await context.commit(SET_PROMOS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/promo', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/promo/${param.id}`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/promo/${param}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVE_REJECT_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/promo/${param.id}/review`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_DISABLE_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/promo/${param.id}/status`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DETAIL_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/promo/${param}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_HISTORY_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/promo/${param}/history`)
        .then(async ({ data }) => {
          await context.commit(SET_PROMO_HISTORIES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_PROMOS](state, data) {
    try {
      let rows = [];
      if (data.count) {
        rows = data.rows.map((v) => {
          ['promo_start_at', 'promo_end_at'].forEach((kv) => {
            v[`${kv}_str`] = moment(v[kv]).format('DD/MM/YYYY');
          });
          v.promo_type_str = v.promo_type.replace('_', ' ').split(' ').map((v) => upperFirst(v)).join(' ');
          v.discount_type_str = upperFirst(v.config.voucher_type_discount || v.config.adminfee_type_discount);
          if (v.promo_type === 'voucher_code') {
            if (v.config.voucher_type_discount === 'percent') {
              v.discount_amount_str = v.config.voucher_discount;
            } else if (v.config.voucher_type_discount === 'fixed') {
              v.discount_amount_str = `Rp. ${v.config.voucher_discount.toLocaleString()}`;
            }
          } else if (v.promo_type === 'admin_fee') {
            if (v.config.adminfee_type_discount === 'percent') {
              v.discount_amount_str = v.config.adminfee_discount;
            } else if (v.config.adminfee_type_discount === 'fixed') {
              v.discount_amount_str = `Rp. ${v.config.adminfee_discount.toLocaleString()}`;
            }
          }
          v.review_status_str = v.review_status === 'approved' ? 'Approved' : 'In Review';
          v.review_status_color = v.review_status === 'approved' ? 'tag-success' : 'tag-default';
          if (v.review_status === 'rejected') {
            v.review_status_str = 'Rejected';
            v.review_status_color = 'tag-danger';
          }

          // v.status_str = v.promo_status === 1 ? 'Active' : 'Inactive';
          if(v.promo_status === 1){
            v.status_str = 'Active';
          }else if(v.promo_status === 0){
            v.status_str = 'Inactive';
          }else if(v.promo_status === 2){
            v.status_str = 'Disabled';
          }

          // v.status_color_str = v.promo_status === 1 ? 'tag-success' : 'tag-warning';
          if(v.promo_status === 1){
            v.status_color_str = 'tag-success';
          }else if(v.promo_status === 0){
            v.status_color_str = 'tag-warning';
          }else if(v.promo_status === 2){
            v.status_color_str = 'tag-danger';
          }
          return v;
        });
      }
      data.rows = rows;
      state.promos = data;
    } catch (error) {
      console.log(error);
    }
  },
  [SET_PROMO_HISTORIES](state, data) {
    try {
      if (data.rows && data.rows.length > 0) {
        data.rows = data.rows.map((v) => {
          v.action_str = 'Create new promotion';
          v.dot_color = '#ff8f15';
          if (v.action === 'update') {
            v.action_str = 'Update promotion content';
            v.dot_color = '#f1c40f';
          } else if (v.action === 'reject') {
            v.action_str = 'Reject promotion content';
            v.dot_color = '#cd201f';
          } else if (v.action === 'approve') {
            v.action_str = 'Approve promotion content';
            v.dot_color = '#5eba00';
          }
          v.role_name = v.user.role.name;
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.created_at_simple = moment(v.created_at).format('DD MMM YYYY');
          return v;
        });
      }
      state.promo_histories = data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
