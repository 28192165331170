import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_COMPANIES = 'getCompanies';
export const IMPORT_EWA_TIER = 'importEwaTier';
export const DETAIL_COMPANIES = 'detailCompanies';
export const CREATE_COMPANIES = 'createCompanies';
export const UPDATE_COMPANIES = 'updateCompanies';
export const DELETE_COMPANIES = 'deleteCompanies';
export const REGISTER_MBAYAR = 'registerMbayar';
export const VALIDATE_OTP_MBAYAR = 'validateOtpMbayar';
export const CHECK_MBAYAR_BALANCE = 'checkBalanceMbayar';
export const GET_COMPANIES_PRIVATE = 'getCompaniesPrivateUser';
export const GET_CITY = 'getCity';
export const GET_PROVINCE = 'getProvince';
export const GET_WALLET_HISTORIES = 'getWalletHistories';
export const DOWNLOAD_WALLET_HISTORIES = 'downloadWalletHistories';
export const GET_BANK_LIST_CASHOUT = 'getBankList';
export const INQUIRY_CASHOUT = 'inquiryCashout';
export const INQUIRY_OTP_CASHOUT = 'inquiryOTPCashout';
export const PROCESS_CASHOUT = 'processCashout';
export const APPROVE_CREATE_COMPANY = 'approveCreateCompany';
export const GET_DRAFT_IMPORT_USER = 'getDraftImportUser';
export const APPROVAL_DRAFT_IMPORT_USER = 'approvelDraftImportUser';
// new
export const GET_REQUEST_COMPANY = 'getRequestCompany';
export const UPDATE_REQUEST_JOIN = 'updateRequestJoin';


// mutations
export const SET_COMPANIES = 'setCompanies';
export const SET_CITY = 'setCity';
export const SET_PROVINCE = 'setProvince';
export const SET_DETAIL_COMPANIES = 'setDetailCompanies';
export const SET_COMPANIES_PRIVATE = 'setCompaniesPrivateUser';
export const SET_DRAFT_IMPORT_USER = 'setDraftImportUser';
// NEW
export const SET_REQUEST_COMPANY = 'setRequestCompany';



const status = {
  active: {
    text: 'Active',
    color: 'tag-success',
  },
  waiting_approval: {
    text: 'Waiting Approval',
    color: 'tag-warning',
  },
  not_active: {
    text: 'Not Active',
    color: 'tag-secondary',
  },
};

const status_request = {
  submitted: {
    text: 'Submitted',
    color: 'tag-span',
  },
  waiting_verification: {
    text: 'Waiting Verification',
    color: 'tag-warning',
  },
  approved: {
    text: 'Approved',
    color: 'tag-success',
  },
  accepted: {
    text: 'Accepted',
    color: 'tag-success',
  },
  rejected_by_company: {
    text: 'Rejected by Company',
    color: 'tag-danger',
  },
  rejected_by_bd: {
    text: 'Rejected by BD',
    color: 'tag-danger',
  },
  rejected_by_hr: {
    text: 'Rejected by HR',
    color: 'tag-danger',
  },
  cancelled: {
    text: 'Cancelled',
    color: 'tag-danger',
  },
};
const draft_status = {
  approved: {
    text: 'Approved',
    color: 'tag-success',
  },
  waiting_approval: {
    text: 'Waiting Approval',
    color: 'tag-warning',
  },
  rejected: {
    text: 'Rejected',
    color: 'tag-danger',
  },
};

const state = {
  companies: {},
  company_private_user: {},
  city: {},
  province: {},
  draft_import_user: {},
  request_join: {}
};

const getters = {
  companies(state) {
    return state.companies;
  },
  company_private_user(state) {
    return state.company_private_user;
  },
  city(state) {
    return state.city;
  },
  province(state) {
    return state.province;
  },
  draft_import_user(state) {
    return state.draft_import_user;
  },
  request_join(state) {
    return state.request_join;
  },
};

const actions = {
  [GET_COMPANIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/companies', param)
        .then(async ({ data }) => {
          await context.commit(SET_COMPANIES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_COMPANIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/companies', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [IMPORT_EWA_TIER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/companies/private-user/ewa/tier', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_COMPANIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/companies/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_COMPANIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/companies/${param.id}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_REQUEST_COMPANY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/submission/list`, param)
        .then(async ({ data }) => {
          await context.commit(SET_REQUEST_COMPANY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_REQUEST_JOIN](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/companies/submission/status`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REGISTER_MBAYAR](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/companies/mbayar/submit?action=${param.action}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VALIDATE_OTP_MBAYAR](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/companies/mbayar/validate', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHECK_MBAYAR_BALANCE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}/balance`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_COMPANIES_PRIVATE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}/private-user`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_COMPANIES_PRIVATE, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_CITY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/users/city`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_CITY, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PROVINCE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/users/province`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_PROVINCE, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [GET_WALLET_HISTORIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}/balance-histories`, param.query)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_WALLET_HISTORIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}/balance-histories/download`, param.query)
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_BANK_LIST_CASHOUT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/companies/cashout/bank-list', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INQUIRY_CASHOUT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/companies/cashout/inquiry', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INQUIRY_OTP_CASHOUT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/companies/cashout/otp?company_id=${param.company_id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PROCESS_CASHOUT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/companies/cashout', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVE_CREATE_COMPANY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/companies/${param.id}/approval`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DRAFT_IMPORT_USER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}/private-user/draft`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_DRAFT_IMPORT_USER, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVAL_DRAFT_IMPORT_USER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/companies/${param.id}/private-user/draft`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DETAIL_COMPANIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/companies/${param.id}`)
        .then(async ({ data }) => {
          await context.commit(SET_DETAIL_COMPANIES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_COMPANIES](state, data) {
    if (!data.count) {
      data.rows = [];
      data.count = 0;
    } else {
      data.rows = data.rows.map((v) => {
        v.status_str = status[v.status]?.text;
        v.status_color = status[v.status]?.color;
        if (v.status === 'not_active') {
          v.status_color = 'tag-secondary';
        }
        v.mbayar_status = v.merchant_mbayar ? 'Active' : 'Not Active';
        v.mbayar_enabled = Boolean(v.merchant_mbayar);
        v.mbayar_status_color = v.merchant_mbayar ? 'tag-primary' : 'tag-default';
        v.mb_package = v.mybenefit_package ? v.mybenefit_package.package_name : '-';
        v.myben_period = v.mb_package !== '-' ? [moment(v.mybenefit_package_start_at, 'YYYY-MM-DD').format('DD MMM YYYY'), moment(v.mybenefit_package_end_at, 'YYYY-MM-DD').format('DD MMM YYYY')].join(' - ') : '-';
        v.mb_package_color = `tag-${v.mb_package.toLowerCase()}`;
        v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
        v.balance_loading = false;
        v.wallet_balance = 'Rp. 0';
        return v;
      });
    }
    state.companies = data;
  },
  [SET_REQUEST_COMPANY](state, data) {
    if (!data.count) {
      data.rows = [];
      data.count = 0;
    } else {
      data.rows = data.rows.map((v) => {
        v.status_str = status_request[v.status]?.text;
        v.status_color = status_request[v.status]?.color;
        if (v.status === 'submitted') {
          v.status_color = 'tag-secondary';
        } else if (v.status === 'waiting_verification') {
          v.status_color = 'tag-warning'
        } else if (v.source_type === 'approved') {
          v.status_color = 'tag-success';
        } else if (v.source_type === 'rejected_by_company') {
          v.status_color = 'tag-danger';
        } else if (v.source_type === 'rejected_by_bd') {
          v.status_color = 'tag-danger';
        } else if (v.source_type === 'rejected_by_hr') {
          v.status_color = 'tag-danger';
        } else if (v.source_type === 'cancelled') {
        v.status_color = 'tag-danger';
        }
        return v;
      });
    }
    state.request_join = data;
  },
  [SET_DETAIL_COMPANIES](state, data) {
    let rows = [];
    if (data) {
      if (data.setting) {
        let admin_fee_ewa = data.setting.admin_fee_ewa;
        data.setting.admin_fee_ewa_str = admin_fee_ewa ? `Rp. ${admin_fee_ewa.toLocaleString('id-ID')}` : 'Rp. 0';
      }
    }else{
      data.rows = [];
      data.count = 0;
    }
    data.rows = rows;
    state.companies = data;
  },
  [SET_COMPANIES_PRIVATE](state, data) {
    if (!data.count) {
      state.company_private_user = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = v.user_status ? 'Active' : 'Inactive';
      v.status_color = v.user_status ? 'tag-success' : 'tag-danger';
      v.login_at_str = v.login_at ? moment(v.login_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    data.rows.sort((a, b) => a.full_name.localeCompare(b.full_name, undefined, { sensitivity: 'base' }));
    state.company_private_user = data;
  },
  [SET_PROVINCE](state, data) {
    if (data && data.rows) {
      state.province = data;
    } else {
      state.province = [];
    }
  },
  [SET_CITY](state, data) {
    if (data && data.rows) {
      state.city = data;
    } else {
      state.city = [];
    }
  },
  [SET_DRAFT_IMPORT_USER](state, data) {
    if (!data.count) {
      state.draft_import_user = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = draft_status[v.status]?.text || 'Unknown';
      v.status_color = draft_status[v.status]?.color || 'tag-secondary';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.uploader_name = v.user_uploader?.full_name || v.user_uploader?.name;
      v.approver_name = v.user_approver?.full_name || v.user_approver?.name || '-';
      Object.assign(v, v.imported_data.data);
      return v;
    });
    state.draft_import_user = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
