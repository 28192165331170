import Vue from 'vue';
import {
  CalendarIcon,
  CalendarTimeIcon,
  WritingIcon,
  MailIcon,
  DeviceMobileIcon,
  CakeIcon,
  MailboxIcon,
  Category2Icon,
  BuildingFactory2Icon,
} from 'vue-tabler-icons';

Vue.use(
  CalendarIcon,
  CalendarTimeIcon,
  WritingIcon,
  MailIcon,
  DeviceMobileIcon,
  CakeIcon,
  MailboxIcon,
  Category2Icon,
  BuildingFactory2Icon,
);
