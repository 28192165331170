import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_REWARDS = 'getRewards';
export const POST_REWARDS = 'postRewards';
export const UPDATE_REWARDS = 'updateRewards';

// mutations
export const SET_REWARDS = 'setRewards';

const state = {
  rewards: {},
};

const getters = {
  rewards(state) {
    return state.rewards;
  },
};

const actions = {
  [GET_REWARDS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/rewards', param)
        .then(async ({ data }) => {
          await context.commit(SET_REWARDS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REWARDS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/rewards', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_REWARDS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/rewards/${param.id}`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_REWARDS](state, data) {
    let rows = [];
    if (data.count) {
      rows = data.rows.map((v) => {
        ['start_at', 'end_at'].forEach((kv) => {
          v[`${kv}_str`] = v[kv] ? moment(v[kv]).format('DD MMMM YYYY') : '-';
          v.status_str = v.status === 1 ? 'Active' : 'Inactive';
          v.status_color_str = v.status === 1 ? 'tag-success' : 'tag-warning';
        });
        return v;
      });
    }
    data.rows = rows;
    state.rewards = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
