import { event } from 'jquery';
import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_GAMIFICATION_LIST = 'getGamificationList';
export const GET_BY_ID_GAMIFICATION = 'getByIdGamification';
export const GET_INQUIRY_GAMIFICATION = 'getInquiryGamification';
export const CREATE_EVENT_GAMIFICATION = 'createEventGamification'
export const UPDATE_EVENT_GAMIFICATION = 'updateEventGamification'
export const DELETE_EVENT_GAMIFICATION = 'deleteEventGamification'
export const UPDATE_STATUS_EVENT_GAMIFICATION = 'updateStatusEventGamification'
export const GET_TRANSACTION_GAMIFICATION = 'getTransactionGamification';

// mutations
export const SET_GAMIFICATION_LIST = 'setGamificationList';
export const SET_ID_GAMIFICATION = 'setIdGamification';
export const SET_INQUIRY_GAMIFICATION = 'setInquiriyGamification';

export const SET_TRANSACTION_GAMIFICATION = 'setTransaction_Gamification';
const status_event = {
  completed: {
    text: 'Completed',
    color: 'tag-success',
  },
  ongoing: {
    text: 'Ongoing',
    color: 'tag-warning',
  },
};

const type = {
  daily_login: {
    text: 'Daily Login',
  },
  transaction_reward: {
    text: 'Transaction Reward',
  },
};
const tx_status = {
  0: {
    text: 'Unclaimed',
    color: 'tag-default',
  },
  1: {
    text: 'Ready',
    color: 'tag-default',
  },
  2: {
    text: 'Claimed',
    color: 'tag-success',
  },
  3: {
    text: 'Skipped',
    color: 'tag-warning',
  },
};
const tx_status_gami = {
  hold: {
    text: 'Hold',
    color: 'tag-default',
  },
  submitted: {
    text: 'Submitted',
    color: 'tag-default',
  },
  publish: {
    text: 'Publish',
    color: 'tag-success',
  },
  draft: {
    text: 'Draft',
    color: 'tag-warning',
  },
};
const user_type = {
  private: {
    text: 'Private',
  },
  all: {
    text: 'All',
  },
  public: {
    text: 'Public',
  },
};
const state = {
  gamifications: {},
  transaction_gamifications: {},
  by_id_gamifications: {},
  inquiry_gamifications: {}
};

const getters = {
  gamifications(state) {
    return state.gamifications; 
  },
  by_id_gamifications(state) {
    return state.by_id_gamifications; 
  },
  inquiry_gamifications(state) {
    return state.inquiry_gamifications; 
  },
  transaction_gamifications(state) {
    return state.transaction_gamifications; 
  },
};

const actions = {
    [GET_GAMIFICATION_LIST](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/gamification/events', param)
          .then(async ({ data }) => {
            await context.commit(SET_GAMIFICATION_LIST, data.data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [GET_BY_ID_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.get(`api/gamification/events/${param.id}`, param.data)
          .then(async ({ data }) => {
            await context.commit(SET_ID_GAMIFICATION, data.data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [GET_INQUIRY_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.get(`api/gamification/events/inquiry`, param.data)
          .then(async ({ data }) => {
            await context.commit(SET_INQUIRY_GAMIFICATION, data.data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [CREATE_EVENT_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.post(`api/gamification/events`, param.data)
          .then(async ({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [UPDATE_EVENT_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.put(`api/gamification/events/${param.id}`, param.data)
          .then(async ({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [UPDATE_STATUS_EVENT_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.patch(`api/gamification/events/status/${param.id}`, param.data)
          .then(async ({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [DELETE_EVENT_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        ApiService.delete(`api/gamification/events/${param.id}`, param.data)
          .then(async ({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [GET_TRANSACTION_GAMIFICATION](context, param) {
      return new Promise((resolve, reject) => {
        // ApiService.get('/api/gamification/transactions/list?page=1&size=10', param)
        // ApiService.get(`api/v1/transactions/history?${param.owned_by}`, param)
        ApiService.get(`api/gamification/transactions/history?owned_by=${param.owned_by}`)
          .then(async ({ data }) => {
            await context.commit(SET_TRANSACTION_GAMIFICATION, data.data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
};

const mutations = {
  [SET_GAMIFICATION_LIST](state, data) {
    if (!data.count) {
      state.gamifications = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.data = data.data.map((v) => {
      v.type_str = v.type && type[v.type] ? type[v.type].text : '-';
      v.user_type_str = v.user_type && user_type[v.user_type] ? user_type[v.user_type].text : '-';
      v.event_status_str = v.status && tx_status_gami[v.status] ? tx_status_gami[v.status].text : '-';
      if (!v.status) {
        v.event_status_color = 'tag-default';
      } else if (tx_status_gami[v.status]) {
        v.event_status_color = tx_status_gami[v.status].color;
      } else {
        v.event_status_color = 'tag-default'; 
      }
      v.updated_at_str = v.updated_at ? moment(v.updated_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.gamifications = data;
  },
  [SET_TRANSACTION_GAMIFICATION](state, data) {
    if (!data.data || data.data.length === 0) {
      state.transaction_gamifications = {
        count: 0,
        data: [],
      };
      return;
    }
    const transformedData = data.data.map((v) => {
      v.type_str = v.type && type[v.type] ? type[v.type].text : '-';
      v.user_type_str = v.user_type && user_type[v.user_type] ? user_type[v.user_type].text : '-';
      v.event_status_str = v.status && status_event[v.status] ? status_event[v.status].text : '-';
      if (!v.status) {
        v.event_status_color = 'tag-default';
      } else if (status_event[v.status]) {
        v.event_status_color = status_event[v.status].color;
      } else {
        v.event_status_color = 'tag-default';
      }
      v.transaction_status_str = v.details.status && tx_status[v.details.status]
        ? tx_status[v.details.status].text
        : '-';

      v.transaction_status_color = v.details.status && tx_status[v.details.status]
        ? tx_status[v.details.status].color
        : 'tag-default';
      v.date_str = v.date ? moment(v.date).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.transaction_gamifications = {
      count: data.count,
      data: transformedData,
    };
  },
  [SET_ID_GAMIFICATION](state, data) {
    state.by_id_gamifications = data;
  },
  [SET_INQUIRY_GAMIFICATION](state, data) {
    state.inquiry_gamifications = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};