import { log } from 'async';
import ApiService from './api.service';

// actions
export const GET_MERCHANTS = 'getMerchants';
export const CREATE_MERCHANT = 'createMerchant';
export const UPDATE_MERCHANT = 'updateMerchant';
export const PREORDER_LIST_MERCHANT = 'getPreOrderList';
export const PREORDER_VOUCHER_LIST_MERCHANT = 'getVoucherPreOrderList';
export const PREORDER_SUBMIT = 'submitPreOrder';
export const MERCHANT_INVOICE_LIST = 'merchantInvoiceList';
export const INVOICE_SUBMIT = 'invoiceSubmit';

// mutations
export const SET_MERCHANTS = 'setMerchants';

const state = {
  merchants: {},
};

const getters = {
  merchants(state) {
    return state.merchants;
  },
};

const actions = {
  [GET_MERCHANTS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/merchants', param)
        .then(async ({ data }) => {
          await context.commit(SET_MERCHANTS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_MERCHANT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/merchants', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_MERCHANT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/merchants/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PREORDER_LIST_MERCHANT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/merchants/${param.id}/po`, param.query)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PREORDER_VOUCHER_LIST_MERCHANT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/merchants/${param.id}/voucher`, param.query)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PREORDER_SUBMIT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/merchants/${param.id}/po`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MERCHANT_INVOICE_LIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/merchants/${param.id}/invoices`, param.query)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_SUBMIT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/merchants/${param.id}/invoices/${param.query.action}`)
        .then( ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_MERCHANTS](state, data) {
    if (data.count) {
      data.rows = data.rows.map((v) => {
        v.status_str = v.merchant_status === 1 ? 'Active' : 'Inactive';
        v.status_color = v.merchant_status === 1 ? 'tag-success' : 'tag-danger';
        return v;
      });
    } else {
        data.rows = [];
        data.count = 0;
    }
        state.merchants = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
