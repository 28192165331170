import ApiService from './api.service';
import { getToken, saveToken, destroyToken } from './jwt.service';

// actions
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CHANGE_PASSWORD = 'changePassword';
export const MY_PROFILE = 'myProfile';
export const INIT_2FA = 'init2FA';
export const ACTIVATE_2FA = 'activate2FA';

// mutations
export const PURGE_AUTH = 'logOut';
export const SET_FORBIDDEN = 'setForbidden';
export const SET_AUTH = 'setUser';
export const SET_PROFILE = 'setProfile';
// export const SET_REDIRECT_PATH = 'setRedirectPath'; 

const state = {
  isAuthenticated: !!getToken(),
  myProfile: {},
  notifications: [],
  is_forbidden: false,
  user_id: {},
  user_email: {},
  // redirectPath: null, 
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentMyProfile(state) {
    return state.myProfile;
  },
  currentNotifications(state) {
    return state.notifications;
  },
  isForbidden(state) {
    return state.is_forbidden;
  },
  user_id(state) {
    return state.user_id;
  },
  user_email(state) {
    return state.user_email;
  },
  // redirectPath(state) { 
  //   return state.redirectPath;
  // },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/auth/login', credentials)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/auth/logout')
        .then(async ({ data }) => {
          await context.commit(PURGE_AUTH);
          window.location = 'login';
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CHANGE_PASSWORD](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch('api/auth', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MY_PROFILE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/me')
        .then(async ({ data }) => {
          await context.commit(SET_PROFILE, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INIT_2FA]() {
    return new Promise((resolve, reject) => {
      ApiService.post('api/auth/init-2fa')
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ACTIVATE_2FA](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/auth/activate-2fa', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.myProfile = data.users;
    localStorage.setItem('user_id', data.users.id);
    state.user_id = data.users.id; 
    localStorage.setItem('user_email', data.users.email);
    state.user_email = data.users.email; 
    saveToken(data.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    destroyToken();
  },
  [SET_FORBIDDEN](state, value) {
    state.is_forbidden = value;
  },
  [SET_PROFILE](state, data) {
    state.myProfile = data.me;
  },
  // [SET_REDIRECT_PATH](state, path) { 
  //   state.redirectPath = path;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};