import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_MISSIONS = 'getMissions';
export const POST_MISSIONS = 'postMissions';
export const UPDATE_DATA = 'updateData';
export const DELETE_DATA = 'deleteData';

// mutations
export const SET_MISSIONS = 'setMissions';

const state = {
  missions: {},
};

const getters = {
  missions(state) {
    return state.missions;
  },
};

const actions = {
  [GET_MISSIONS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/missions', param)
        .then(async ({ data }) => {
          await context.commit(SET_MISSIONS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_MISSIONS](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/missions', credentials)
        .then(async ({ data }) => {
          await context.commit(SET_MISSIONS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_DATA](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/missions/${param.id}`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_DATA](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/missions/${param}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_MISSIONS](state, data) {
    let rows = [];
      if (data.count) {
        rows = data.rows.map((v) => {
        if (v[`${kv}_str`]) v[`${kv}_str`] = v[kv] ? moment(v[kv]).format('DD MMMM YYYY') : '-';
        ['start_at', 'end_at'].forEach((kv) => {
          v.status_str = v.mission_status === 1 ? 'Active' : 'Inactive';
          v.status_color_str = v.mission_status === 1 ? 'tag-success' : 'tag-warning';
        });
        return v;
      });
    }
      data.rows = rows;
      state.missions = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
