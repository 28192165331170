import Vue from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueLoading, {
  color: '#FF8F15',
  loader: 'dots',
  width: 100,
  height: 100,
  backgroundColor: '#000000',
  opacity: 0.7,
  zIndex: 2000,
  lockScroll: true,
  blur: null,
});
