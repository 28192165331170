<template>
  <div id="app">
    <DashboardLayout v-if="$route.meta.layout == 'dashboard'">
      <forbidden v-if="isForbidden && $route.name.toLowerCase() != 'dashboard'" />
      <router-view :key="$route.fullPath" />
    </DashboardLayout>
    <TopOnlyLayout v-else-if="$route.meta.layout == 'topOnly'">
      <router-view />
    </TopOnlyLayout>
    <DefaultLayout v-else>
      <router-view />
    </DefaultLayout>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: (s) => (s ? `${s} | V-Dashboard` : 'V-Dashboard'),
    };
  },
  computed: {
    isForbidden() {
      return this.$store.getters.isForbidden;
    },
  },
  components: {
    DashboardLayout: () => import('./ui/layouts/DashboardLayout.vue'),
    DefaultLayout: () => import('./ui/layouts/DefaultLayout.vue'),
    TopOnlyLayout: () => import('./ui/layouts/TopOnlyLayout.vue'),
    forbidden: () => import('@/ui/pages/error/403.vue'),
  },
};
</script>
