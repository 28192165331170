import axios from 'axios';
import store from '..';
import { PURGE_AUTH, SET_FORBIDDEN } from './auth';
import { getToken } from './jwt.service';

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_VDASHBOARD_API_URL;
    axios.defaults.timeout = 30000;
  },

  setHeader() {
    if (getToken()) axios.defaults.headers.common.authorization = `Bearer ${getToken()}`;
    store.commit(SET_FORBIDDEN, false);
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  async throwLogin(error) {
    if (error?.response?.status === 401) {
      store.commit(PURGE_AUTH);
      window.location = '/login';
    } else if (error?.response?.status === 403) {
      // window.location = '/403';
      store.commit(SET_FORBIDDEN, true);
    }
    return error;
  },

  async get(resource, params, options = {}) {
    this.setHeader();
    return axios.get(`${resource}`, { params, ...options }).catch((error) => {
      this.throwLogin(error);
      throw error;
    });
  },
  post(resource, params, query) {
    this.setHeader();
    return axios.post(`${resource}`, params, { params: query });
    // return axios({ method: 'post', url: `${resource}`, data: params, params: query });
  },

  upload(resource, params) {
    this.setHeader();
    const formData = new FormData();
    formData.append('media', params);
    return axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  update(resource, slug, params) {
    this.setHeader();
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    this.setHeader();
    return axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    this.setHeader();
    return axios.patch(`${resource}`, params);
  },

  delete(resource, params) {
    this.setHeader();
    return axios.delete(resource, params);
  },
};

export default ApiService;