import ApiService from './api.service';
import moment from 'moment';

// actions
export const POST_TOPUPS = 'postTopUps';
export const GET_VENDORS = 'getVendors';
export const GET_DEPOSITS = 'getDeposits';
export const POST_ADJUSTMENT = 'postAdjustment';
export const GET_VENDORS_BALANCE = 'getVendorBalance';
export const POST_REPORTS = 'postReports';
export const GET_DETAIL_REPORTS = 'getDetailReports';
export const DOWNLOAD_DEPOSITS = 'downloadTopUps';

// mutations
export const SET_VENDORS = 'setVendors';
export const SET_DEPOSITS = 'setDeposits';
export const SET_VENDORS_BALANCE = 'setVendorsBalance';
export const SET_DETAIL_REPORTS = 'setDetailReports';

const state = {
  vendors_topup: {},
  deposits_topup: {},
  vendors_last_balance: {},
  detail_reports: {},
};

const getters = {
  vendors_topup(state) {
    return state.vendors_topup;
  },
  vendors_deposits(state) {
    return state.vendors_deposits;
  },
  vendors_last_balance(state) {
    return state.vendors_last_balance;
  },
  detail_reports(state) {
    return state.detail_reports;
  },
};

const actions = {
  [GET_VENDORS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/deposit/vendor', param)
        .then(async ({ data }) => {
          await context.commit(SET_VENDORS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_VENDORS_BALANCE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/deposit/vendor/balance', param)
        .then(async ({ data }) => {
          await context.commit(SET_VENDORS_BALANCE, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DEPOSITS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/deposit/history', param)
        .then(async ({ data }) => {
          await context.commit(SET_DEPOSITS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_DEPOSITS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/deposit/history', param)
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_TOPUPS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/deposit/topup', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_ADJUSTMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/deposit/adjustment', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_REPORTS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/deposit/report', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DETAIL_REPORTS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/deposit/${param}`)
        .then(async ({ data }) => {
          await context.commit(SET_DETAIL_REPORTS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_VENDORS](state, data) {
    let rows = [];
    // if (data.count) {
    rows = data.rows.map((v) => {
      ['created_at'].forEach((kv) => {
        v[`${kv}_str`] = v[kv] ? moment(v[kv]).format('DD MMMM YYYY, HH:mm') : '-';
      });
      return v;
    });
    // }
    data.rows = rows;
    state.vendors_topup = data;
  },
  [SET_VENDORS_BALANCE](state, data) {
    state.vendors_last_balance = data;
  },
  [SET_DEPOSITS](state, data) {
    let rows = [];
    // if (data.count) {
    rows = data.rows.map((v) => {
      v.report_date_str = v.report_date ? moment(v.report_date).format('DD MMMM YYYY') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).format('DD MMMM YYYY, HH:mm') : '-';
      v.beginning_balance_str = `Rp. ${v.beginning_balance ? v.beginning_balance.toLocaleString() : 0} `;
      v.end_balance_str = `Rp. ${v.end_balance ? v.end_balance.toLocaleString() : 0} `;
      v.credit_str = `Rp. ${v.credit ? v.credit.toLocaleString() : 0} `;
      v.total_topup_str = `Rp. ${v.total_topup ? v.total_topup.toLocaleString() : 0} `;
      v.nominal_adjustment_str = `Rp. ${v.nominal_adjustment ? v.nominal_adjustment.toLocaleString() : 0} `;
      return v;
    });
    // }
    data.rows = rows;
    state.vendors_deposits = data;
  },
  [SET_DETAIL_REPORTS](state, data) {
    let rows = [];
    // if (data.count) {
    rows = data.rows.map((v) => {
      v.report_date_str = v.report_date ? moment(v.report_date).format('DD MMMM YYYY') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).format('DD MMMM YYYY, HH:mm') : '-';
      v.beginning_balance_str = `Rp. ${v.beginning_balance ? v.beginning_balance.toLocaleString() : 0} `;
      v.end_balance_str = `Rp. ${v.end_balance ? v.end_balance.toLocaleString() : 0} `;
      v.credit_str = `Rp. ${v.credit ? v.credit.toLocaleString() : 0} `;
      v.total_topup_str = `Rp. ${v.total_topup ? v.total_topup.toLocaleString() : 0} `;
      v.nominal_adjustment_str = `Rp. ${v.nominal_adjustment ? v.nominal_adjustment.toLocaleString() : 0} `;
      v.total_adjustment_str = `Rp. ${v.total_adjustment ? v.total_adjustment.toLocaleString() : 0} `;
      v.total_credit_order_detail_str = `Rp. ${v.total_credit_order_detail ? v.total_credit_order_detail.toLocaleString() : 0} `;

      if (v.topup_detail) {
        v.topup_detail = v.topup_detail.map((vd) => {
          vd.nominal_str = vd.nominal ? `Rp. ${vd.nominal.toLocaleString('id-ID')}` : 'Rp. 0';
          vd.created_at_str = vd.created_at ? moment(vd.created_at).format('DD MMMM YYYY') : '-';
          vd.topup_at_str = vd.topup_at ? moment(vd.topup_at).format('DD MMMM YYYY') : '-';
          return vd;
        });
      }

      if (v.adjustment_detail) {
        v.adjustment_detail = v.adjustment_detail.map((vd) => {
          vd.nominal_str = vd.nominal ? `Rp. ${vd.nominal.toLocaleString('id-ID')}` : 'Rp. 0';
          vd.created_at_str = vd.created_at ? moment(vd.created_at).format('DD MMMM YYYY') : '-';
          vd.report_date_str = vd.report_date ? moment(vd.report_date).format('DD MMMM YYYY') : '-';
          return vd;
        });
      }

      if (v.order_detail) {
        v.order_detail = v.order_detail.map((vd) => {
          vd.base_price_str = vd.base_price ? `Rp. ${vd.base_price.toLocaleString('id-ID')}` : 'Rp. 0';
          vd.created_at_str = vd.created_at ? moment(vd.created_at).format('DD MMMM YYYY') : '-';
          return vd;
        });
      }
      return v;
    });
    // }
    data.rows = rows;
    // state.vendors_deposits = data;
    state.detail_reports = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
