import ApiService from './api.service';

// actions
export const GET_LMS = 'getLms';

// mutations
// export const SET_LMS = 'setLms';

const state = {
  lms: {},
};

const getters = {
  lms(state) {
    return state.lms;
  },
};

const actions = {
  [GET_LMS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/academic/course', param)
        .then(async ({ data }) => {
          // await context.commit(SET_LMS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

// const mutations = {
//   [SET_LMS](state, data) {
//     let rows = [];
//     if (data.count) {
//       try {
//         rows = data.rows.map((v) => {
//           v.academic_price_money_str = `Rp. ${v.academic_price_money ? v.academic_price_money.toLocaleString() : 0}`;
//           v.source_name = v.academic_name;
//           v.source_id = v.academic_id;
//           v.status_str = v.academic_status === 1 ? 'Active' : 'Inactive';
//           v.status_color_str = v.academic_status === 1 ? 'tag-success' : 'tag-danger';
//           return v;
//         });
//       } catch (error) {
//         console.log(error);
//       }
//     }
//     data.rows = rows;
//     state.academys = data;
//   }
// };

export default {
  state,
  getters,
  actions,
  // mutations,
};
