import moment from 'moment';
import { upperFirst } from 'lodash';
import ApiService from './api.service';

// actions
export const GET_INTERNALUSERS = 'getInternalUsers';
export const CREATE_INTERNALUSERS = 'createInternalUsers';
export const UPDATE_INTERNALUSERS = 'updateInternalUsers';
export const DELETE_INTERNALUSERS = 'deleteInternalUsers';

// mutations
export const SET_INTERNALUSERS = 'setInternalUsers';

const state = {
  internal_users: {},
};

const getters = {
  internal_users(state) {
    return state.internal_users;
  },
};

const actions = {
  [GET_INTERNALUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/internal-users', param)
        .then(async ({ data }) => {
          await context.commit(SET_INTERNALUSERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_INTERNALUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/internal-users', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_INTERNALUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/internal-users/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_INTERNALUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/internal-users/${param.id}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_INTERNALUSERS](state, data) {
    data.rows = data.rows.map((v) => {
      v.status_str = upperFirst(v.status);
      v.status_color = 'tag-success';
      if (v.status === 'not_active') {
        v.status_color = 'tag-secondary';
      }
      v.last_login_at_str = v.last_login_at ? moment(v.last_login_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.internal_users = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
