import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_VOUCHERS = 'getVouchers';
export const DETAIL_VOUCHERS = 'getDetailVouchers';
export const VOUCHER_LIST = 'getVoucherList';
export const CREATE_VOUCHERS = 'createVouchers';
export const UPDATE_VOUCHERS = 'updateVouchers';
export const UPLOAD_VOUCHERS = 'uploadVouchers';
export const VOUCHER_VENDOR = 'getVoucherVendor';
export const VOUCHER_LIST_UV = 'getListVoucherUv';
export const SYNC_VENDOR = 'syncVendor';

// mutations
export const SET_VOUCHERS = 'setVouchers';
export const SET_VOUCHER = 'setVoucher';
export const SET_VOUCHER_LIST = 'setVoucherList';
export const SET_VOUCHER_VENDOR = 'setVoucherVendor';

const state = {
  vouchers: {},
  voucher: {},
  voucher_list: {},
  vendors: {},
};

const detail_status = {
  1: {
    text: 'In stock',
    color: 'tag-default',
  },
  2: {
    text: 'Booked',
    color: 'tag-warning',
  },
  3: {
    text: 'Claimed',
    color: 'tag-success',
  },
  4: {
    text: 'Used',
    color: 'tag-primary',
  },
  5: {
    text: 'Refund Stock',
  },
  0: {
    text: 'Inactive',
  },
};

const getters = {
  vouchers(state) {
    return state.vouchers;
  },
  voucher(state) {
    return state.voucher;
  },
  voucher_list(state) {
    return state.voucher_list;
  },
  vendors(state) {
    return state.vendors;
  },
};

const actions = {
  [GET_VOUCHERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/vouchers', param)
        .then(async ({ data }) => {
          await context.commit(SET_VOUCHERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DETAIL_VOUCHERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/vouchers/${param}`)
        .then(async ({ data }) => {
          await context.commit(SET_VOUCHER, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VOUCHER_LIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/vouchers/${param.id}/list`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_VOUCHER_LIST, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VOUCHER_VENDOR](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/vouchers/vendor', param.query)
        .then(async ({ data }) => {
          await context.commit(SET_VOUCHER_VENDOR, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_VOUCHERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/vouchers', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_VOUCHERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/vouchers/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SYNC_VENDOR](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/product/${param.vendor_id}/voucher/sync`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPLOAD_VOUCHERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/vouchers/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VOUCHER_LIST_UV](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ultravoucher/voucher', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_VOUCHERS](state, data) {
    try {
      let rows = [];
      if (data.count) {
        rows = data.rows.map((v) => {
          ['voucher_start_at', 'voucher_end_at', 'voucher_created_at'].forEach((kv) => {
            if (v[kv]) v[`${kv}_str`] = moment(v[kv]).format('DD/MM/YYYY');
          });
          v.status_str = v.voucher_status === 1 ? 'Active' : 'Inactive';
          v.status_color = v.voucher_status === 1 ? 'tag-success' : 'tag-danger';
          v.voucher_price_str = `Rp. ${v.voucher_price ? v.voucher_price.toLocaleString() : 0}`;
          v.voucher_base_price_str = `Rp. ${v.voucher_base_price ? v.voucher_base_price.toLocaleString() : 0} `;
          return v;
        });
      }
      data.rows = rows;
      state.vouchers = data;
    } catch (error) {
      console.log(error);
    }
  },
  [SET_VOUCHER](state, data) {
    state.voucher = data.voucher;
  },
  [SET_VOUCHER_LIST](state, data) {
    /**
     * v_detail_status
     * 1. in stock
     * 2. booking
     * 3. claim
     * 4. used
     * 5. not active
     */
    let rows = [];
    if (data.count) {
      rows = data.rows.map((v) => {
        v.expired_date_str = moment(v.v_expired_at).format('DD MMM YYYY');
        v.upload_date_str = moment(v.v_detail_created_at).format('DD MMM YYYY');
        v.status_str = detail_status[v.v_detail_status].text;
        v.status_color_str = detail_status[v.v_detail_status].color;
        return v;
      });
      data.rows = rows;
    }
    state.voucher_list = data;
  },
  [SET_VOUCHER_VENDOR](state, data) {
    state.vendors = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
