import { upperFirst } from 'lodash';
import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_DISBURSEMENTS = 'getDisbursement';
export const CREATE_DISBURSEMENT = 'createDisbursement';
export const UPDATE_DISBURSEMENT = 'updateDisbursement';
export const DELETE_DISBURSEMENT = 'deleteDisbursement';
export const APPROVE_REJECT_DISBURSEMENT = 'approveRejectDisbursement';
export const ENABLE_DISABLE_DISBURSEMENT = 'enableDisableDisbursement';
export const GET_DETAIL_DISBURSEMENT = 'getDetailDisbursement';
export const GET_HISTORY_DISBURSEMENT = 'getHistoryDisbursement';
export const DOWNLOAD_DETAIL_DISBURSEMENT = 'downloadDetailDisbursement';
export const VALIDATE_RECIPIENT_DISBURSEMENT = 'validateRecipientDisbursement';
export const UPDATE_RECIPIENT_DISBUSEMENT = 'updateRecipientDisbursement';
export const DELETE_RECIPIENT_DISBUSEMENT = 'deleteRecipientDisbursement';

// mutations
export const SET_DISBURSEMENTS = 'setDisbursement';
export const SET_DISBURSEMENT_HISTORIES = 'setDisbursementHistories';
export const SET_DETAIL_DISBURSE = 'setDisbursementDetailList';

const status_color = {
  waiting_approval: 'tag-warning',
  success: 'tag-success',
  on_process: 'tag-info',
  reject: 'tag-danger',
  failed: 'tag-danger',
  pending: 'tag-info',
  invalid: 'tag-warning',
};

const state = {
  disbursements: {},
  disbursement_histories: {},
  disburse_recipient: {},
  selected_batch: {},
};

const getters = {
  disbursements(state) {
    return state.disbursements;
  },
  disbursement_histories(state) {
    return state.disbursement_histories;
  },
  disburse_recipient(state) {
    return state.disburse_recipient;
  },
  selected_batch(state) {
    return state.selected_batch;
  },
};

const actions = {
  [GET_DISBURSEMENTS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/disbursement', param)
        .then(async ({ data }) => {
          await context.commit(SET_DISBURSEMENTS, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/disbursement', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/disbursement/${param.id}`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/disbursement/${param}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [APPROVE_REJECT_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/disbursement/${param.id}/${param.action}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_DISABLE_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/disbursement/${param.id}/status`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DETAIL_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/disbursement/${param.id}`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_DETAIL_DISBURSE, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_HISTORY_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/disbursement/${param}/histories`)
        .then(async ({ data }) => {
          await context.commit(SET_DISBURSEMENT_HISTORIES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_DETAIL_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/disbursement/${param.id}/download`, {}, {})
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VALIDATE_RECIPIENT_DISBURSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/disbursement/validate-recipient', param)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_RECIPIENT_DISBUSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/disbursement/${param.id}/recipients`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_RECIPIENT_DISBUSEMENT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/disbursement/${param.id}/recipients`, { data: param.data })
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_DISBURSEMENTS](state, data) {
    try {
      let rows = [];
      if (data.count) {
        rows = data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.company_name = v.company.company_name;
          v.amount_str = `Rp. ${(v.total_amount || 0).toLocaleString()}`;
          v.status_str = v.status.split('_').map((v) => upperFirst(v)).join(' ');
          v.status_color = status_color[v.status];
          return v;
        });
      }
      data.rows = rows;
      state.disbursements = data;
    } catch (error) {
      console.log(error);
    }
  },
  [SET_DISBURSEMENT_HISTORIES](state, data) {
    try {
      if (data.rows && data.rows.length > 0) {
        data.rows = data.rows.map((v) => {
          v.action_str = 'Create new disbursement request';
          v.dot_color = '#ff8f15';
          if (v.action === 'update') {
            v.action_str = 'Update disbursement request';
            v.dot_color = '#f1c40f';
          } else if (v.action === 'reject') {
            v.action_str = 'Reject disbursement request';
            v.dot_color = '#cd201f';
          } else if (v.action === 'approve') {
            v.action_str = 'Approve disbursement request';
            v.dot_color = '#5eba00';
          } else if (v.action === 'update_recipients') {
            v.action_str = 'Update disbursement recipients';
            v.dot_color = '#2aad7b';
          } else if (v.action === 'delete_recipients') {
            v.action_str = 'Delete disbursement recipients';
            v.dot_color = '#e62c17';
          }
          v.user_name = v.user.name;
          v.role_name = v.user.role.name;
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.created_at_simple = moment(v.created_at).format('DD MMM YYYY');
          return v;
        });
      }
      state.disbursement_histories = data;
    } catch (error) {
      console.log(error);
    }
  },
  [SET_DETAIL_DISBURSE](state, data) {
    let rows = [];
    if (data.count) {
      rows = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        v.amount_str = `Rp. ${(v.amount || 0).toLocaleString()}`;
        v.status_str = v.status.split('_').map((v) => upperFirst(v)).join(' ');
        v.status_color = status_color[v.status];
        return v;
      });
    }
    data.rows = rows;
    state.disburse_recipient = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
