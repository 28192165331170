// import moment from 'moment';
// import { upperFirst } from 'lodash';
import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_EWALIST = 'getEwaList';
export const INQUIRY_APPROVAL = 'inquiryApproval';
export const SUBMIT_APPROVAL = 'submitApproval';
export const GET_DETAIL_EWALIST = 'getDetailEwaList';
export const GET_ALL_DETAIL_EWALIST = 'getAllDetailEwaList';
export const ROLLBACK_APPROVAL = 'rollbackApproval';
export const INVOICE_LIST = 'getInvoiceList';
export const INVOICE_GENERATE = 'generateNewInvoice';
export const INVOICE_REGENERATE = 'regenerateInvoice';
export const SETTLE_OVER_DUE_INVOICE = 'settleOverDueInvoice';
export const INVOICE_INQUIRY = 'inquiryNewInvoice';
export const INVOICE_DETAIL = 'getInvoiceDetail';
export const INVOICE_UPDATE_STATUS = 'updateInvoiceStatus';
export const INVOICE_PARTIAL_UPDATE_STATUS = 'partialUpdateInvoiceStatus';
export const CHECK_EWA_BALANCE = 'checkEwaBalance';
export const GET_EWA_WALLET_HISTORIES = 'getHistoriesEwaWallet';
export const DOWNLOAD_EWA_WALLET_HISTORIES = 'downloadHistoriesEwaWallet';
export const GET_EWA_REPORT = 'getEwaReport';

// mutations
export const SET_EWALIST = 'setEwaList';
export const SET_DETAIL_EWALIST = 'setEwaDetailList';
export const SET_DETAIL_INVOICE = 'setEwaDetailInvoice';

const disburse_status = {
  waiting_approval: {
    label: 'Waiting Approval',
    color: 'tag-warning',
  },
  waiting_disbursement: {
    label: 'Waiting Disbursement',
    color: 'tag-brand',
  },
  rejected: {
    label: 'Rejected',
    color: 'tag-danger',
  },
  completed: {
    label: 'Success',
    color: 'tag-success',
  },
  failed: {
    label: 'Failed',
    color: 'tag-danger',
  },
};

const status = {
  waiting_approval: {
    label: 'Waiting Approval',
    color: 'tag-warning',
  },
  rejected: {
    label: 'Rejected',
    color: 'tag-danger',
  },
  approved: {
    label: 'Approved',
    color: 'tag-success',
  },
};

const invoice_status = {
  waiting_payment: {
    label: 'Waiting Payment',
    color: 'tag-warning',
  },
  partial_paid: {
    label: 'Partial Paid',
    color: 'tag-info',
  },
  paid: {
    label: 'Paid',
    color: 'tag-success',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'tag-danger',
  },
};

const state = {
  ewa_list: {},
  detail_ewa_list: {},
  detail_invoice: {},
};

const getters = {
  ewa_list(state) {
    return state.ewa_list;
  },
  detail_ewa_list(state) {
    return state.detail_ewa_list;
  },
  detail_invoice(state) {
    return state.detail_invoice;
  },
};

const actions = {
  [GET_EWALIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa', param)
        .then(async ({ data }) => {
          await context.commit(SET_EWALIST, data.data);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INQUIRY_APPROVAL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/ewa/${param.id}/inquiry`, param.query)
        .then(async ({ data }) => {
          resolve(data.data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [SUBMIT_APPROVAL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/ewa/${param.id}/approval`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [GET_DETAIL_EWALIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/ewa/${param.id}/detail`, param.query)
        .then(async ({ data }) => {
          if (param.query.action !== 'download') await context.commit(SET_DETAIL_EWALIST, data.data);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [ROLLBACK_APPROVAL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/ewa/${param.id}/rollback/${param.current_state}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  // need
  [INVOICE_LIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/ewa/${param.id}/invoice`, param.query)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_GENERATE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/ewa/${param.id}/invoice`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_REGENERATE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/ewa/${param.id}/invoice/regenerate`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [SETTLE_OVER_DUE_INVOICE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/ewa/${param.id}/invoice/regenerate/overdue/fix`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_INQUIRY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/ewa/${param.id}/invoice/inquiry`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_DETAIL](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/ewa/${param.id}/invoice/${param.invoice_id}`, param.query)
        .then(async ({ data }) => {
          await context.commit(SET_DETAIL_INVOICE, data.data);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_UPDATE_STATUS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/ewa/${param.company_id}/invoice/${param.invoice_id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [INVOICE_PARTIAL_UPDATE_STATUS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/ewa/${param.company_id}/invoice`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [CHECK_EWA_BALANCE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa-wallet-balance', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_EWA_WALLET_HISTORIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa-wallet-balance/histories', param.query)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_EWA_WALLET_HISTORIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa-wallet-balance/histories/download', param.query)
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_EWA_REPORT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa/report', param)
        .then(async (response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  [GET_ALL_DETAIL_EWALIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ewa/all-history', param.query)
        .then(async ({ data }) => {
          if (param.query.action !== 'download') await context.commit(SET_DETAIL_EWALIST, data.data);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_EWALIST](state, data) {
    if (data.count) {
      data.rows = data.rows.map((v) => {
        v.company_name = v.company.name;
        v.total_amount_str = `Rp.${v.total_amount.toLocaleString()}`;
        v.total_admin_fee_str = `Rp.${v.total_admin_fee.toLocaleString()}`;
        v.disburse_amount = v.total_amount - v.total_admin_fee;
        v.disburse_amount_str = `Rp.${v.disburse_amount.toLocaleString()}`;
        return v;
      });
    } else {
      data.count = 0;
      data.rows = [];
    }
    state.ewa_list = data;
  },
  [SET_DETAIL_EWALIST](state, data) {
    if (!data.count) {
      state.detail_ewa_list = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.company_name = v.company?.company_name || '-';
      v.request_amount_str = `Rp.${v.request_amount?.toLocaleString() || '-'}`;
      v.admin_fee_str = `Rp.${v.admin_fee?.toLocaleString() || '-'}`;
      v.requestor_name = v.requestor?.full_name || '-';
      v.requestor_phone_number = v.requestor?.phone_number || '-';
      v.status_str = status[v.status]?.label || '-';
      v.status_color = status[v.status]?.color || 'default-color';
      if (v.status === 'rejected') {
        v.disburse_status_str = '-';
        v.disburse_status_color = 'tag-default';
      } else {
        v.disburse_status_str = disburse_status[v.disburse_status]?.label || '-';
        v.disburse_status_color = disburse_status[v.disburse_status]?.color || 'default-color';
      }
      v.created_at_str = v.created_at ? moment(v.created_at).format('DD MMM YYYY, HH:mm') : '-';
      v.approved_at_str = v.approved_at ? moment(v.approved_at).format('DD MMM YYYY, HH:mm') : '-';
      v.rejected_at_str = v.rejected_at ? moment(v.rejected_at).format('DD MMM YYYY, HH:mm') : '-';
      v.disburse_at_str = v.disburse_at ? moment(v.disburse_at).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.detail_ewa_list = data;
  },
  [SET_DETAIL_INVOICE](state, data) {
    if (!data.count) {
      state.detail_invoice = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      ['created_at', 'paid_at'].forEach((k) => {
        v[`${k}_str`] = !v[k] ? '-' : moment(v[k]).format('DD MMM YYYY, HH:mm');
      });
      v.invoice_number = v.ewa_invoice.invoice_number;
      v.amount_str = `Rp. ${v.amount.toLocaleString()}`;
      v.status_str = invoice_status[v.status].label;
      v.status_color = invoice_status[v.status].color;
      v.requestor_name = v.ewa.requestor.full_name;
      v.request_amount_str = `Rp.${v.amount.toLocaleString()}`;
      v.admin_fee_str = `Rp.${v.ewa.admin_fee.toLocaleString()}`;
      v.disburse_at_str = moment(v.ewa.disburse_at).format('DD MMM YYYY, HH:mm');
      return v;
    });
    state.detail_invoice = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
