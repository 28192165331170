// import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_PPOB_LIST = 'getPpob';
export const GET_PPOB_BY_VENDOR = 'getPpobByVendor';
export const UPDATE_PPOB = 'updatePpob';
export const ENABLE_DISABLE_PPOB = 'enableDisablePpob';

// mutations
export const SET_PPOB = 'setPpob';

const state = {
  ppob: {},
};

const getters = {
  ppob(state) {
    return state.ppob;
  },
};

const actions = {
  [GET_PPOB_LIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/ppob', param)
        .then(async ({ data }) => {
          await context.commit(SET_PPOB, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PPOB_BY_VENDOR](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/ppob/vendor/${param.id}`, param.query)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_PPOB](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/ppob/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_DISABLE_PPOB](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/ppob/${param.id}/${param.status}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_PPOB](state, data) {
    try {
      data.rows = data.rows.map((v) => {
        v.active_biller_name = v.v_price?.vendor ? v.v_price?.vendor.vendor_name : '-';
        v.active_biller_product_code = v.v_price?.product_code ? v.v_price?.product_code : '-';
        v.status_str = v.status ? 'Active' : 'Inactive';
        v.status_color = v.status ? 'tag-success' : 'tag-danger';
        v.biller_sell_price_str = v.v_price ? `Rp. ${parseInt(v.v_price.base_price, 10).toLocaleString()}` : '-';
        v.sell_price_str = v.v_price ? `Rp. ${parseInt(v.v_price.price, 10).toLocaleString()}` : '-';
        v.vendor_id = v.v_price?.vendor ? v.v_price?.vendor.vendor_id : null;
        return v;
      });
      state.ppob = data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
