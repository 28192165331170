import ApiService from './api.service';

// actions
export const GET_CATEGORYS = 'getCategorys';

// mutations
export const SET_CATEGORYS = 'setCategorys';

const state = {
  categorys: {},
};

const getters = {
  categorys(state) {
    return state.categorys;
  },
};

const actions = {
  [GET_CATEGORYS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/categorys', param)
        .then(async ({ data }) => {
          await context.commit(SET_CATEGORYS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_CATEGORYS](state, data) {
    state.categorys = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
