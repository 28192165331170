import ApiService from './api.service';

// actions
export const GET_SOCIALS = 'getSocials';

// mutations
export const SET_SOCIALS = 'setSocials';

const state = {
  socials: {},
};

const getters = {
  socials(state) {
    return state.socials;
  },
};

const actions = {
  [GET_SOCIALS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/socials', param)
        .then(async ({ data }) => {
          await context.commit(SET_SOCIALS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_SOCIALS](state, data) {
    if (data.count) {
      data.rows = data.rows.filter((v) => {
        v.source_name = v.social_name;
        v.source_id = v.social_id;
        return v;
      });
    }
    console.log('social', data);
    state.socials = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
